<template>
  <div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <router-link to="/">
          <img :src="siteLogo" alt="" class="logo-login" />
        </router-link>
        <div class="kt-login__title login-title">
          <h3>{{ $t("TITLE_RESET_PASSWORD") }}</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form login-form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="email-input-group"
            label=""
            label-for="email-input"
            class="pt-7"
          >
            <div
              role="alert"
              v-show="errors.length"
              class="alert fade show alert-danger"
            >
              <div
                class="alert-text"
                v-for="(error, i) in errors"
                :key="'error_' + i"
              >
                {{ Array.isArray(error) ? error[0] : error }}
              </div>
            </div>
            <div
              role="alert"
              v-show="success.length"
              class="alert fade show alert-success"
            >
              <div class="alert-text">
                {{ success }}
              </div>
            </div>
            <b-form-input
              id="email-input"
              name="email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="email-input-live-feedback"
              v-bind:placeholder="$t('EMAIL')"
            ></b-form-input>

            <b-form-invalid-feedback id="email-input-live-feedback">
              {{ $t("VALID_EMAIL") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="password-input-group"
            label=""
            label-for="password-input"
          >
            <b-form-input
              type="password"
              id="password-input"
              name="password"
              v-bind:placeholder="$t('PASSWORD')"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="password-input-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="password-input-live-feedback">
              {{ $t("VALID_PASSWORD") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="password-input-group"
            label=""
            label-for="password-input"
          >
            <b-form-input
              type="password"
              id="password_confirmation-input"
              name="password_confirmation"
              v-bind:placeholder="$t('CONFIRM_PASSWORD')"
              v-model="$v.form.password_confirmation.$model"
              :state="validateState('password_confirmation')"
              aria-describedby="input-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-live-feedback">
              {{ $t("VALID_PASSWORD_CONFIRM") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div
            class="kt-login__actions form-group d-flex flex-wrap justify-content-between align-items-center"
          >
            <router-link
              :to="{ name: 'login' }"
              class="text-dark-60 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              {{ $t("TITLE_RESET_PASSWORD") }}
            </router-link>
            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-dark font-weight-bold px-9 py-4 my-3 font-size-3"
            >
              {{ $t("SAVE") }}
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right:before {
  right: 5px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { RESET } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "reset",
  data() {
    return {
      form: {
        email: this.$route.query.email,
        password: "",
        password_confirmation: ""
      }
    };
  },
  mounted() {
    // check if current user is authenticated

    if (this.isAuthenticated) {
      this.$router.push({ name: "dashboard" });
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: this.$route.query.email,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$store.state.auth.success = "";
      this.$store.state.auth.errors = [];

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const password_confirmation = this.$v.form.password_confirmation.$model;
      const token = this.$route.query.token;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add("spinner", "spinner-white", "spinner-right");

      // send login request
      this.$store
        .dispatch(RESET, {
          email: email,
          password: password,
          password_confirmation: password_confirmation,
          token: token
        })
        // go to which page after successfully login
        .then(() => {
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 3000);
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-white",
            "spinner-right"
          );
        });
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      errors: state => state.auth.errors,
      success: state => state.auth.success
    }),
    siteLogo() {
      return process.env.BASE_URL + "assets/media/logos/logo.png";
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.auth.success = "";
    this.$store.state.auth.errors = [];
    next();
  }
};
</script>
